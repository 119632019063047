@import "~antd/dist/antd.css";

@font-face {
  font-family: 'Futura Std';
  src: url('./assets/fonts/FuturaStdBook.otf');
}

@font-face {
  font-family: 'Betterworks';
  src: url('./assets/fonts/Betterworks.otf');
}

.ant-page-header {
  background-color: #131921;
}

.ant-page-header-heading {
  display: flex;
  justify-content: left;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.searchBar {
  margin-top: 4px;
  width: calc(100vw - 550px);
}

.ant-input-search-button {
  background-color: #fdb13f;
  border-color: #fdb13f;
}

.ant-input-search-button:hover {
  background-color: #ff9900;
  border-color: #ff9900;
  cursor: pointer;
}

.login {
  padding: 3px 30px;
  font-weight: bold;
  background-color: #fdb13f;
  border-color: #fdb13f;
  border: solid 2px #fdb13f;
  color: white;
}

.login:hover {
  background-color: #ff9900;
  border: solid 2px white;
  color: white;
}

.login:focus {
  background-color: #ff9900;
  border: solid 2px white;
  color: white;
}

.ant-btn-primary:hover,
.ant-btn:hover {
  background: #262626 !important;
  border-color: #262626 !important;
  color: #fff !important;
  transition: all ease-in-out 0.03s !important;
}

.ant-btn-primary:active,
.ant-btn:active,
.ant-btn-primary:focus,
.ant-btn:focus {
  background: #00fea6 !important;
  border-color: #00fea6 !important;
  color: #000 !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  .ant-btn-primary:hover,
  .ant-btn:hover {
    background: #00fea6 !important;
    border-color: #00fea6 !important;
    color: #000 !important;
    border: solid 1px #00fea6 !important;
    transition: all ease-in-out 0.03s !important;
  }

  .ant-btn-primary:active,
  .ant-btn:active,
  .ant-btn-primary:focus,
  .ant-btn:focus {
    background: #00fea6 !important;
    border-color: #00fea6 !important;
    color: #000 !important;
    border: solid 1px #00fea6 !important;
    outline: none !important;
  }
}

article {
  font-family: "Lato";
}

.ant-steps-icon-dot {
  background-color: #D3D2D1 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #D3D2D1 !important;
}
